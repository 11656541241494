<template>
  <v-container class="mt-5 main-content" >
      <div class="Title" >
      <h1 > > 退会処理</h1>
    </div>
    <div class="d-flex justify-left" style="width:100%;" >
  
      <v-card 
        elevation="4"
        max-width="700"
        width="99%"
        class="mt-10 px-4"
      >
        <v-card-title class="my-1 text--secondary">退会する</v-card-title>

        <v-form ref="form" v-model="valid" lazy-validation  @submit.prevent  class="px-4 ">
          <v-container :loading="loading" >
            <v-text-field
              v-model="password1"
              :error-messages="password1Errors"
              type="password"
              maxlength="32"
              label="パスワード"
              @input="$v.password1.$touch()"
              @blur="$v.password1.$touch()"
            ></v-text-field>

            <v-text-field
              v-model="password2"
              :error-messages="password2Errors"
              type="password"
              maxlength="32"
              label="パスワード(確認用)"
              @input="$v.password2.$touch()"
              @blur="$v.password2.$touch()"
            ></v-text-field>


            <p class="red--text body-2 ma-0 pa-0 " v-for="error in passwordSecondErrors" :key="error"> 
                ・{{error}}
            </p>


            <v-row class="justify-center">
                <v-btn
                  outlined
                  color="narekomu"
                  class="white--text my-8  "
                  width="100px"
                  :loading ="loading"
                  :disabled=" !valid || !checkPassword  || loading"
                  @click="withdraw"
                >
                  退会する
                </v-btn>
            </v-row>
          </v-container>
        </v-form>

      </v-card>
      <DialogStatus 
        :item="sItem"
        v-on:closeEmit="closeStatus"
      />
      
    </div>
  </v-container>
</template>

<script>
import axios from "axios"
const DialogStatus = () => import('@/components/DialogStatus.vue');
import { validationMixin } from 'vuelidate'
import { required ,minLength , maxLength} from 'vuelidate/lib/validators'


  export default {
    name: 'Withdraw',
    components: {  DialogStatus },
    mixins: [validationMixin],
    validations: {
      password1: { required , minLength: minLength(8) ,maxLength: maxLength(32)},
      password2: { required , minLength: minLength(8) ,maxLength: maxLength(32)},
      passwordOld: { required , minLength: minLength(8) ,maxLength: maxLength(32)},
    },
    data: () => ({
      password1:'',
      password2:'',
      passwordOld:'',
      valid:true,
      loading:false,
      sItem:{
        loadText:"通信中...",
        textError:"予期せぬエラーが起きました",
        compText:`パスワードの変更が完了しました。`,
        type:"",
        reloadError:false,
        toTop:false,
        close:true,
      },
    }),

    computed:{

      password1Errors(){
        let errors = [];
        if (!this.$v.password1.$dirty) return errors
        if(!this.$v.password1.required) errors.push("必須項目です")
        else if(!this.$v.password1.minLength) errors.push("パスワードは8文字以上で入力してください")
        else if(!this.$v.password1.maxLength) errors.push("パスワードは32文字以下で入力してください")
        return errors;
      },

      password2Errors(){
        let errors = [];
        if (!this.$v.password2.$dirty) return errors
        if(!this.$v.password2.required) errors.push("必須項目です")
        if(!this.$v.password2.minLength) errors.push("パスワードは8文字以上で入力してください")
        if(!this.$v.password2.maxLength) errors.push("パスワードは32文字以下で入力してください")
        return errors;
      },


      passwordSecondErrors(){
        let errors = [];
        if (!this.$v.password1.$dirty  ||  !this.$v.password2.$dirty) return errors
        if(this.password1.length < 8 || this.password2.length < 8) errors.push("パスワードは8文字以上で入力してください")
        else if(this.password1 != this.password2 ) errors.push("確認用パスワードが違います。")

        return errors;
      },

      checkPassword(){
        if(this.passwordSecondErrors.length==0 && this.password1Errors.length==0 && this.password2Errors.length==0 &&this.$v.password1.$dirty && this.$v.password2.$dirty) return true;
        else return false;
      }
    },

    methods:{
      //パスワードの変更
      withdraw(){
        
        if (this.$refs.form.validate()) {
          this.loading = true;
          this.sItem.type = "load";
          axios({
            method: "DELETE",
            url:`${process.env.VUE_APP_URL}account`,
            headers: {"X-Api-Key": process.env.VUE_APP_KEY, "Content-Type":"application/json" },
            data:{
              "UserId":this.$store.state.user_model.userData.userId,
              "Password":this.password1,
            },
          }).then((response) => {
            if(response.data.results=="complete"){
                console.log(response)
                this.sItem.type = "comp";
                this.sItem.compText = "退会処理が完了しました。5秒後に画面が遷移します。"
                this.loading = false;
                this.$store.dispatch("resetUserData")
                this.$store.dispatch("resetAuthData")
                setTimeout(this.logout,5000);
            }else{
              this.loading = false;
              this.sItem.type = "error";
            }
          }).catch(reason => {
            console.log(reason.response)
            this.loading = false;
            this.sItem.type = "error";
            if(reason.response.status == 401) this.sItem.errorText="パスワードが正しくありません";
            else if(reason.response.status == 406) this.sItem.errorText="有料会員の方は管理者へ退会申請を行って下さい";
            else this.sItem.errorText = reason.response.data;
          });
        }
      },
      closeStatus(){
          this.sItem.type="";

      },

      logout () {

        axios({
          method: "GET",
          url:`${process.env.VUE_APP_URL}logout`,
          headers: {
              "X-Api-Key": process.env.VUE_APP_KEY,
              "SessionId": this.$store.state.auth.login.sessionId
          },
            
        }).then(res => {
            // 成功した場合
            if (res.data) {
              console.log("Logout")
            } 
        }).catch(() => {
              // console.log(err)
        })

        this.$router.go({path: this.$router.currentRoute.path, force: true})
        this.$store.reset()
      },

    }
  }
</script>


<style scoped>

  .login-card {
    width:650px;
    margin:-50px auto 0 auto;
  }


  .Title > h1 {
    color: gray;
    font-weight: 200;
    border-bottom: 1px solid gray;
  }

</style>

